// Dependency
import * as React from "react"
import Fade from 'react-reveal/Fade';
import {graphql} from "gatsby";

// Components
import Layout from "../../components/layouts";
import Video from "../../components/video/video";
import BoxGrid from "../../components/box-grid/box-grid";
import HalfGrid from "../../components/half-grid/half-grid";
import Quote from "../../components/quote/quote";
import TeamGrid from "../../components/team-grid/team-grid";
import MultiMediaGrid from "../../components/half-grid/multi-media-grid";

// Styles
import '../../styles/index.scss';

// Assets
import OGhero from "../../images/hero/gro-advokatbyra.png";
import FooterText from "../../components/footer-text/footer-text";

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en"){
  datoCmsHomePage (locale: {eq: $locale}){
    title
    heroContent
    desktopHero{
      url
    }
    mobileHero{
      url
    }
    mobileVideo{
      url
    }
    desktopVideo{
      url
    }
    workAreas{
      title
      slug
      description
    }
    aboutSubtitle
    aboutText
    aboutLink
    aboutLinkText
    aboutImage{
      fluid{
        ...GatsbyDatoCmsFluid
      }
    }
    coworkerSubtitle
    coworkerText
    coworkerLink
    coworkerLinkText
    coworkerImages{
      fluid{
        ...GatsbyDatoCmsFluid
      }
    }
    quote{
      content
    }
    team{
      name
      image{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      slug
      title
    }
  }
}
`

// Template
const IndexPage = (props) => {

    const metaData = {
        name: 'Private Equity | M&A | Aktieägaravtal | gdpr',
        image:{
            src: OGhero,
        }
    }

    const pageData = props.data.datoCmsHomePage;

    return (
    <section>
        <Layout data={metaData} path={props.location} locale={props.pageContext.locale}>
          {/*  <ParallaxBanner
                className="cd-home-hero"
                layers={[
                    {
                        image: hero,
                        amount: -0.1,
                    }
                ]}
            >
            </ParallaxBanner>*/}
            <div className="cd-home-hero">
                <Video
                    videoSrcURL={pageData.desktopVideo.url}
                    videoTitle={'Hero Video'}
                />
                <div className="cd-background-overlay"></div>
                <div className="cd-home-hero-content">
                    <div className="cd-max-width">
                        <Fade>
                            <div dangerouslySetInnerHTML={{__html: pageData.heroContent}}></div>
                        </Fade>
                    </div>
                </div>
            </div>

            {
                pageData && pageData.workAreas && <BoxGrid locale={props.pageContext.locale} data={pageData.workAreas} />
            }

            <HalfGrid locale={props.pageContext.locale} cname={"cd-half-grid-about"} subTitle={pageData.aboutSubtitle} link={pageData.aboutLink} linkText={pageData.aboutLinkText} image={pageData.aboutImage} content={pageData.aboutText} />

            <MultiMediaGrid locale={props.pageContext.locale} subTitle={pageData.coworkerSubtitle} link={pageData.coworkerLink} linkText={pageData.coworkerLinkText} content={pageData.coworkerText} growImages={pageData.coworkerImages} />

            {
                pageData && pageData.quote &&   <Quote data={pageData.quote} />
            }

            {
                pageData && pageData.team &&   <TeamGrid locale={props.pageContext.locale} data={pageData.team} />
            }
            <FooterText />

        </Layout>
    </section>
  )
}

export default IndexPage
