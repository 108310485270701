// Dependency
import React from "react"
import Reveal from "react-reveal/Reveal";
import Fade from 'react-reveal/Fade';
import {Link} from "gatsby";
import Img from "gatsby-image";

// Components
import HalfGridText from "./half-grid-text";

// Styles
import * as halfGridStyles from './half-grid.module.scss'
import * as multiMediaStyles from './multi-media-grid.module.scss'

// Assets


// Template
const MultiMediaGrid = (props) => {

    return (
        <section className={` ${multiMediaStyles.cdMultiMediaSection}`}>
            <div className="cd-max-width">
                <div className={`${halfGridStyles.cdHalfGrid} ${multiMediaStyles.cdHalfGridTeam} cd-half-grid-multi`}>
                    <HalfGridText cname={'cd-half-grid-text-left'}>
                        <Reveal effect="typewriter">
                            <div>
                                <h6>{props.subTitle}</h6>
                            </div>
                        </Reveal>
                        <Fade bottom >
                            <div dangerouslySetInnerHTML={{ __html: props.content}}></div>
                            <Link to={`/${props.locale}${props.link}`} className="cd-link">{props.linkText} <div><span></span></div></Link>
                        </Fade>
                    </HalfGridText>
                    <div className={`${halfGridStyles.cdHalfGridMedia} cd-half-grid-media`}>
                        <div className={multiMediaStyles.cdMultiMediaGrid}>
                            {props.growImages.map( (growImage, i) => {
                                return (
                                    <Fade key={i} bottom delay={growImage.delay}>
                                        <div className={`${multiMediaStyles.cdMultiMediaItem} cd-multi-media-item`}>
                                            <div className={`${multiMediaStyles.cdMultiMediaItemImg} cd-background-img`}>
                                                <Img fluid={growImage.fluid} alt="" />
                                            </div>
                                        </div>
                                    </Fade>
                                )
                            } )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MultiMediaGrid
