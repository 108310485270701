// Dependency
import React from "react"

// Styles
import * as videoStyles from './video.module.scss'

const Video = ({ videoSrcURL, videoTitle }) => (
    <div className={videoStyles.cdVideo}>
        <video preload="none" loop muted autoPlay playsInline>
            <source title={videoTitle} src={videoSrcURL} type="video/mp4" />
        </video>
    </div>
)
export default Video
